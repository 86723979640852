import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkedinIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Message } from 'seedly-component-library';
import { Link } from 'react-feather';

const ShareSection = props => {
  const { link } = props;
  if (!link) return null;

  return (
    <div className="flex flex-col gap-4 w-full items-center">
      <p>Share this content with your friends!</p>
      <div className="flex gap-4 items-center">
        <div className="cursor-pointer">
          <FacebookShareButton url={link}>
            <FacebookIcon size={47} round />
          </FacebookShareButton>
        </div>
        <div className="cursor-pointer">
          <WhatsappShareButton url={link}>
            <WhatsappIcon size={47} round />
          </WhatsappShareButton>
        </div>
        <div className="cursor-pointer">
          <TelegramShareButton url={link}>
            <TelegramIcon size={47} round />
          </TelegramShareButton>
        </div>
        <div className="cursor-pointer">
          <LinkedinShareButton url={link}>
            <LinkedinIcon size={47} round />
          </LinkedinShareButton>
        </div>
        <div className="cursor-pointer p-[10px] pb-2 rounded-full border border-neutral-700">
          <CopyToClipboard
            onCopy={() => Message.success('Link Copied')}
            text={link}
          >
            <Link size={20} />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default ShareSection;
