import React from 'react';
import { useLottie } from 'lottie-react';
import { cloneDeep } from 'lodash';
import successAnimation from './success.json';

const CreditEarnLottie = () => {
  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(cloneDeep(options));

  return <>{View}</>;
};

export default CreditEarnLottie;
