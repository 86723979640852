import React from 'react';
import Button from 'seedly-component-library/button-new';
import { Gift } from 'react-feather';
import { openRewardLaunchModal } from 'dispatcher/modalDispatcher';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

const NonEnrolledUserDashboard = props => {
  const { isInverted, onJoinNowClick = () => {} } = props;
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex flex-col justify-between w-full">
        {!isInverted && (
          <div className="flex items-center gap-1">
            <Gift size={16} className="text-neutral-400" />
            <p className="text-xs font-bold uppercase text-neutral-400">
              SEEDLY REWARDS
            </p>
          </div>
        )}
        <div
          className={clsx(
            'flex w-full justify-between gap-3 items-center',
            isInverted ? 'flex-col' : 'fex-row',
          )}
        >
          <div className="flex items-center gap-3">
            <Gift size={isInverted ? 42 : 24} color="white" />
            <p className="text-white">
              Join SeedlyRewards and be rewarded for being on Seedly!{' '}
            </p>
          </div>
          <Button
            onClick={() => {
              dispatch(openRewardLaunchModal());
              onJoinNowClick();
              mixpanel.track(trackedEvents.RewardsCollection, {
                param: 'join_now_rewards',
              });
            }}
            isBlock={isInverted}
            variant="primaryWhite"
          >
            Join now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NonEnrolledUserDashboard;
