import React from 'react';
import NonEnrolledUserDashboard from 'pageContents/RewardsContent/RewardCreditCard/NonEnrolledUserDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer';
import ModalActions from 'reducer/modalRedux';
import Dialog from 'seedly-component-library/dialog';
import Button from 'app/seedly-component-library/button-new';
import { useRouter } from 'next/router';
import { useIsEnrolledUser } from 'entity/user/userHooks';
import ShareSection from './ShareSection';
import CreditEarnLottie from './CreditEarnLottie';

const EarnCreditModal = () => {
  const isOpen = useSelector(
    (state: RootState) => state.modal.isCreditEarnModalOpen,
  );
  const meta = useSelector(
    (state: RootState) => state.modal.creditEarnModalMeta,
  );
  const { shareLink, creditsEarned, maxMonthlyCreditReached } = meta;
  const isEnrolled = useIsEnrolledUser();
  const dispatch = useDispatch();
  const router = useRouter();

  let topCopy = 'Thanks for your contribution!';
  let bottomCopy = 'Get rewarded!';

  if (isEnrolled) {
    topCopy = maxMonthlyCreditReached
      ? 'You’re one of our top earners!'
      : 'YOU’VE EARNED';
    bottomCopy = maxMonthlyCreditReached
      ? 'Fantastic'
      : `${creditsEarned} Credits`;
  }

  if (creditsEarned === 0 && !maxMonthlyCreditReached) {
    bottomCopy = 'Good Job!';
    topCopy = 'Thanks for your contribution!';
  }

  return (
    <Dialog
      isOpen={isOpen}
      onCloseClick={() => dispatch(ModalActions.setCreditEarnModal(false))}
    >
      <div className="flex flex-col gap-4 items-center w-full">
        <div className="flex flex-col items-center w-full">
          <p className="text-sm font-bold text-neutral-500">{topCopy}</p>
          <p className="text-[32px] font-bold text-green-600">{bottomCopy}</p>
          <CreditEarnLottie />
        </div>
        {!isEnrolled ? (
          <div className="p-4 rounded-lg bg-purple-200 w-[311px] h-fit">
            <NonEnrolledUserDashboard
              onJoinNowClick={() => {
                dispatch(ModalActions.setCreditEarnModal(false));
              }}
              isInverted
            />
          </div>
        ) : (
          <div className="flex flex-col gap-5 items-center w-[311px]">
            {maxMonthlyCreditReached && (
              <p className="text-xs text-center text-neutral-500">
                Your contribution paid off as you’ve reached the maximum credit
                earning limit. Keep contributing to maintain or unlock a new
                tier!
              </p>
            )}
            <Button
              onClick={() => {
                dispatch(ModalActions.setCreditEarnModal(false));
                router.push('/rewards');
              }}
              isBlock
              variant="outlineGrey"
              size="lg"
            >
              View My Rewards
            </Button>
          </div>
        )}
        <div className="flex flex-col items-center w-full gap-4">
          <div className="horizontal-divider" />
          <ShareSection link={shareLink} />
        </div>
      </div>
    </Dialog>
  );
};

export default EarnCreditModal;
